import Login from '@components/Auth/Login'
import { Button, Text } from '@drdropin-tech/theseus'
import useTranslation from '@hooks/useTranslation'
import { SessionStatus } from '@lib/types/auth'
import useSettingStore from '@stores/useSettingStore'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import pkg from '../../package.json'

const Home = () => {
  const { translations, translate } = useTranslation()
  const { status, data } = useSession()
  const settings = useSettingStore()
  const hasSettings =
    settings.audioinput.id && settings.audiooutput.id && settings.videoinput.id
  if (status === 'loading') return null

  return (
    <>
      {status !== SessionStatus.Authenticated && (
        <div className="bg-black/5 h-full flex justify-center items-center flex-col gap-8">
          <Text as="h2">{translations.home.unauthorized.welcome}</Text>
          <div>
            <Text as="h3">{translations.home.unauthorized.firstTime}</Text>
            <pre className="mt-4 text-base">
              {translations.home.unauthorized.intro}
            </pre>
          </div>
          <Login />
        </div>
      )}
      {status === SessionStatus.Authenticated && (
        <div className="flex justify-center items-center flex-col gap-8">
          <Text as="h2">
            {translate('home.authorized.welcome', {
              name: data?.user.firstName,
            })}
          </Text>

          {!hasSettings && (
            <Link href="/settings" passHref>
              <Button appearance="primary">
                {translations.home.authorized.goToSettings}
              </Button>
            </Link>
          )}
        </div>
      )}
      <div className="absolute bottom-4 left-1/2 -translate-x-1/2">
        {pkg.version}
      </div>
    </>
  )
}

export default Home
